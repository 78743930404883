import { ClientDetails } from '@waitroom/models';
import { version } from '.';
import { branchService } from '../services/branch';
import { browser } from '../services/browser';
import { getFingerPrint } from '../utils/finger-print';

export const getClientDetails = async (): Promise<ClientDetails> => {
  const [fpResponse, branchResponse] = await Promise.allSettled([
    getFingerPrint(),
    branchService.fingerprint.get,
  ]);

  return {
    platform: browser ? browser.getBrowserName(true) : 'n/a',
    platformType: browser ? browser.getPlatformType(true) : 'n/a',
    osName: browser ? browser.getOSName(true) : 'n/a',
    osVersion: browser ? browser.getOSVersion() : 'n/a',
    appVersion: version,
    fingerprint: fpResponse.status === 'fulfilled' ? fpResponse.value : '',
    branchFingerprint:
      branchResponse.status === 'fulfilled' && branchResponse.value ? branchResponse.value : '',
  };
};
