var T = Object.defineProperty;
var O = (e, t, n) => t in e ? T(e, t, { enumerable: !0, configurable: !0, writable: !0, value: n }) : e[t] = n;
var x = (e, t, n) => O(e, typeof t != "symbol" ? t + "" : t, n);
import { errorService as D } from "@waitroom/error-service";
import { LOGGER_SERVICE_BRAID as v } from "@waitroom/models";
import { retryWithBackoff as I, parseStream as S, parseJson as E } from "@waitroom/utils";
let u;
const Y = (e) => {
  u = e.logger;
}, U = (e) => e ? `?${Object.keys(e).map((t) => `${t}=${encodeURIComponent(e[t])}`).join("&")}` : "";
class P {
  constructor() {
    x(this, "handlerRef", { id: -1 });
  }
  get handler() {
    return this.handlerRef.id;
  }
  set handler(t) {
    this.handlerRef.id = t;
  }
  clear() {
    clearTimeout(this.handlerRef.id);
  }
}
function L(e, t, n = new P()) {
  let r = !1;
  const s = () => {
    n.handler = setTimeout(() => {
      e(() => {
        r = !0, n.clear();
      }), r || s();
    }, t);
  };
  return s(), n;
}
var N = window.fetch, J = window.AbortController, A = window.Headers;
async function B(e, t = {}) {
  if (t = { ...t }, t.headers ? t.headers = new A(t.headers) : t.headers = new A(), t.version && console.assert(Array.isArray(t.version), "fetch(): `version` must be an array"), t.parents && console.assert(Array.isArray(t.parents), "fetch(): `parents` must be an array"), t.version && t.headers.set("version", t.version.map(JSON.stringify).join(", ")), t.parents && t.headers.set("parents", t.parents.map(JSON.stringify).join(", ")), t.subscribe && t.headers.set("subscribe", "true"), t.peer && t.headers.set("peer", t.peer), t.headers.set("X-Keep-Alive", "true"), t.cache = "no-cache", t.patches)
    if (console.assert(!t.body, "Cannot send both patches and body"), console.assert(typeof t.patches == "object", "Patches must be object or array"), Array.isArray(t.patches) || (t.patches = [t.patches]), t.patches.length === 1) {
      let i = t.patches[0];
      t.headers.set("Content-Range", `${i.unit} ${i.range}`), t.headers.set("Content-Length", `${new TextEncoder().encode(i.content).length}`), t.body = i.content;
    } else
      t.headers.set("Patches", t.patches.length), t.body = t.patches.map((i) => {
        var g = `content-length: ${new TextEncoder().encode(i.content).length}`, p = `content-range: ${i.unit} ${i.range}`;
        return `${g}\r
${p}\r
\r
${i.content}\r
`;
      }).join(`\r
`);
  var n = t.signal, r = new J();
  t.signal = r.signal, n && n.addEventListener("abort", () => r.abort()), u == null || u.service(v, 5, "Client fetch", t);
  var s = await N(e, t);
  s.subscribe = f, s.subscription = { [Symbol.asyncIterator]: y };
  const a = s.headers.get("X-Keep-Alive");
  function f(i, g) {
    if (!s.ok) throw new Error("Request returned not ok status:", s.status);
    if (s.bodyUsed)
      throw new Error("This response's body has already been read", s);
    H(
      s.body,
      // Each time something happens, we'll either get a new
      // version back, or an error.
      (p, o) => {
        if (!o)
          i(p);
        else {
          const b = r.signal.aborted;
          if (r.abort(), g) g(o, b);
          else throw "Unhandled network error in subscription";
        }
      },
      a
    );
  }
  function y() {
    var i = !1, g = [], p = null, o = null;
    return {
      async next() {
        if (g.length > 0) return { done: !1, value: g.shift() };
        var b = new Promise((d, _) => {
          p = d, o = _;
        });
        i || (i = !0, f(
          (d) => p(d),
          (d) => o(d)
        ));
        var w = await b;
        return p = (d) => g.push(d), o = (d) => {
          throw d;
        }, { done: !1, value: w };
      }
    };
  }
  return s;
}
async function H(e, t, n) {
  const r = e.getReader(), s = j(t);
  let a;
  if (n) {
    const f = (parseInt(n) + 15) * 1e3;
    a = L(() => s.checkTimeout(f), 1e4), s.keepAlive = !0;
  }
  for (; ; ) {
    let f, y;
    try {
      const i = await r.read();
      f = i.done, y = i.value;
    } catch (i) {
      a && a.clear(), s.timedOut || t(null, i);
      return;
    }
    if (f) {
      a && a.clear(), s.timedOut || t(null, "Connection closed");
      return;
    }
    s.read(y);
  }
}
const j = (e) => ({
  // A parser keeps some parse state
  state: { input: [] },
  // And reports back new versions as soon as they are ready
  cb: e,
  // Timestamp of last input
  last: Date.now(),
  timedOut: !1,
  keepAlive: !1,
  // You give it new input information as soon as you get it, and it will
  // report back with new versions as soon as it finds them.
  read(t) {
    const n = t;
    if (this.last = Date.now(), !(this.keepAlive && n.length > 12 && n[0] === 88 && n[1] == 45 && n[2] === 75 && n[3] === 101 && n[4] === 101 && n[5] === 112 && n[6] === 45 && n[7] === 65 && n[8] === 108 && n[9] === 105 && n[10] === 118 && n[11] === 101))
      for (this.state.input.push(...t); this.state.input.length; ) {
        try {
          this.state = G(this.state);
        } catch (r) {
          this.cb(null, r);
          return;
        }
        if (this.state.result === "success")
          this.cb({
            version: this.state.version,
            parents: this.state.parents,
            body: this.state.body,
            patches: this.state.patches,
            // Output extra_headers if there are some
            extra_headers: R(this.state.headers)
          }), this.state = { input: this.state.input };
        else if (this.state.result === "error") {
          this.cb(null, this.state.message);
          return;
        }
        if (this.state.result == "waiting") break;
      }
  },
  checkTimeout(t) {
    const n = Date.now() - this.last;
    u == null || u.service(v, 5, `Interval diff: ${n}`), n > t && (this.timedOut || (u == null || u.service(v, 3, "Connection timed out"), e(null, "Connection timed out")), this.timedOut = !0);
  }
});
function G(e) {
  if (!e.headers) {
    const t = C(e.input);
    if (t.result === "error") return t;
    if (t.result === "waiting")
      return e.result = "waiting", e;
    e.headers = t.headers, e.version = e.headers.version, e.parents = e.headers.parents, e.input = t.input;
  }
  return z(e);
}
function C(e) {
  const t = F(e);
  if (!t) return { result: "waiting" };
  const n = t.header_string, r = n.length, s = {}, a = /(:?[\w-_]+):\s?(.*)\r?\n?/gy;
  let f, y = !1;
  for (; f = a.exec(n); )
    s[f[1].toLowerCase()] = f[2], a.lastIndex === r && (y = !0);
  return y ? ("version" in s && (s.version = JSON.parse("[" + s.version + "]")), "parents" in s && (s.parents = JSON.parse("[" + s.parents + "]")), "patches" in s && (s.patches = JSON.parse(s.patches)), e = t.remaining_bytes, { result: "success", headers: s, input: e }) : {
    result: "error",
    message: 'Parse error in headers: "' + JSON.stringify(n.substr(a.lastIndex)) + '"',
    headers_so_far: s,
    last_index: a.lastIndex,
    headers_length: r
  };
}
function $(e) {
  const t = e.match(/(\S+)( (.*))?/);
  return t && { unit: t[1], range: t[3] || "" };
}
function z(e) {
  const t = parseInt(e.headers["content-length"]);
  if (isNaN(t)) {
    if (e.headers.patches != null) {
      e.patches = e.patches || [];
      let n = e.patches[e.patches.length - 1];
      for (; !(e.patches.length === e.headers.patches && (e.patches.length === 0 || "content" in n)); ) {
        if ((!n || "content" in n) && (n = {}, e.patches.push(n)), !("headers" in n)) {
          const r = C(e.input);
          if (r.result === "error") return r;
          if (r.result === "waiting")
            return e.result = "waiting", e;
          n.headers = r.headers, e.input = r.input;
        }
        {
          if (!("content-length" in n.headers))
            return {
              result: "error",
              message: "no content-length in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          if (!("content-range" in n.headers))
            return {
              result: "error",
              message: "no content-range in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          const r = parseInt(n.headers["content-length"]);
          if (e.input.length < r)
            return e.result = "waiting", e;
          const s = $(n.headers["content-range"]);
          if (!s)
            return {
              result: "error",
              message: "cannot parse content-range in patch",
              patch: n,
              input: new TextDecoder("utf-8").decode(new Uint8Array(e.input))
            };
          n.unit = s.unit, n.range = s.range, n.content = new TextDecoder("utf-8").decode(
            new Uint8Array(e.input.slice(0, r))
          ), n.extra_headers = R(n.headers), delete n.headers, e.input = e.input.slice(r);
        }
      }
      return e.result = "success", e;
    }
  } else {
    if (t > e.input.length)
      return e.result = "waiting", e;
    if (e.result = "success", e.headers["content-range"]) {
      const n = $(e.headers["content-range"]);
      if (!n)
        return {
          result: "error",
          message: "cannot parse content-range",
          range: e.headers["content-range"]
        };
      e.patches = [
        {
          unit: n.unit,
          range: n.range,
          content: new TextDecoder("utf-8").decode(
            new Uint8Array(e.input.slice(0, t))
          )
          // Question: Perhaps we should include headers here, like we do for
          // the Patches: N headers below?
          // headers: state.headers
        }
      ];
    } else
      e.body = new TextDecoder("utf-8").decode(
        new Uint8Array(e.input.slice(0, t))
      );
    return e.input = e.input.slice(t), e;
  }
  return {
    result: "error",
    message: "cannot parse body without content-length or patches header"
  };
}
function R(e) {
  const t = Object.assign({}, e), n = ["version", "parents", "patches", "content-length", "content-range"];
  for (let r = 0; r < n.length; r++) delete t[n[r]];
  if (Object.keys(t).length !== 0)
    return t;
}
function F(e) {
  let t = 0;
  for (; e[t] === 13 || e[t] === 10; )
    t++;
  if (t === e.length)
    return null;
  let n = t, r = 0;
  for (; n < e.length; ) {
    if (e[n] === 10 && e[n + 1] === 10) {
      r = 2;
      break;
    }
    if (e[n] === 10 && e[n + 1] === 13 && e[n + 2] === 10) {
      r = 3;
      break;
    }
    if (e[n] === 13 && e[n + 1] === 10 && e[n + 2] === 10) {
      r = 3;
      break;
    }
    if (e[n] === 13 && e[n + 1] === 10 && e[n + 2] === 13 && e[n + 3] === 10) {
      r = 4;
      break;
    }
    n++;
  }
  if (n === e.length)
    return null;
  const s = e.slice(t, n), a = new TextDecoder("utf-8").decode(new Uint8Array(s));
  return {
    remaining_bytes: e.slice(n + r),
    header_string: a
  };
}
const X = {
  method: "GET",
  shouldReconnect: !0,
  subscribe: !0,
  skipRetryErrorCodes: [404]
}, q = (e) => (t) => typeof t == "object" && (e.includes(t.status) || e.includes(t.code)), Z = {
  _client: B,
  _connections: {},
  _listeners: /* @__PURE__ */ new Set(),
  _emitEvent(e) {
    this._listeners.forEach((t) => t(e));
  },
  updateStatus(e, t) {
    const n = this._connections[e];
    n && (n.state.status = t);
  },
  config: { interceptors: {} },
  addListener(e) {
    return this._listeners.add(e), () => this._listeners.delete(e);
  },
  clearListeners() {
    return this._listeners.clear();
  },
  close: function(e) {
    const t = this._connections[e];
    t && (t.abort.abort(`Close called for ${e}`), this.updateStatus(e, "closed"), this._emitEvent({
      type: "close",
      key: e
    }), this._connections[e] && (this._connections[e].connection = void 0));
  },
  fetch: async function(e) {
    const { onError: t, onRetry: n } = this.config.interceptors, r = { ...X, ...e }, s = r.key || r.url || "", a = `${r.baseURL}${r.url}${U(r.params)}`;
    return await new Promise(async (y, i) => {
      var p;
      const g = () => new Promise(async (o, b) => {
        var m;
        if (!!((m = this._connections[s]) != null && m.connection)) {
          const c = `Connection already exists ${s}`;
          if (u == null || u.service(v, 5, c), !r.shouldReconnect) return i(new Error(c));
          this.close(s);
        }
        const d = new AbortController();
        if (r.signal) {
          if (r.signal.aborted)
            return this.close(s), y(void 0);
          r.signal.addEventListener("abort", () => {
            this.close(s);
          });
        }
        const _ = this._client(a, {
          ...r,
          signal: d.signal
        });
        this._connections[s] = {
          state: {
            status: "connecting",
            url: a,
            key: s,
            startTime: Date.now(),
            arguments: r
          },
          abort: d,
          connection: _
        };
        let h;
        try {
          h = await _, r._retry = !1, this.updateStatus(s, "connected"), this._emitEvent({
            type: "connection",
            key: s,
            data: { response: h, args: r }
          });
        } catch (c) {
          return d.signal.aborted ? i(c) : (this.updateStatus(s, "reconnecting"), b(c));
        }
        if (h.status && h.status > 399)
          return b({
            code: h.code || h.status,
            data: h == null ? void 0 : h.data,
            success: !1,
            error: h
          });
        if (e.subscribe !== !1)
          h.subscribe(
            (c) => {
              if (c.body) {
                r._retry = !1;
                const l = E(c.body);
                return this._emitEvent({
                  type: "body",
                  key: s,
                  data: l
                }), r.onFetched(l), l ? y(l) : i(c);
              }
              if (c.patches && (this._emitEvent({
                type: "patch",
                key: s,
                data: c.patches
              }), r.onPatch)) {
                const l = c.patches.map(
                  (k) => ({
                    op: k.unit,
                    path: k.range,
                    value: k.content ? E(k.content) : void 0
                  })
                );
                r.onPatch(l);
              }
            },
            async (c, l) => (this._emitEvent({
              type: "error",
              key: s,
              data: { error: c, wasAborted: l }
            }), l ? (this.updateStatus(s, "closed"), y(void 0)) : (r.onError && await r.onError(r, c), this.updateStatus(s, "reconnecting"), b(c)))
          );
        else {
          if (!h.body) return i(void 0);
          const c = typeof h.body == "string" ? h.body : h.body instanceof ReadableStream ? await S(h.body) : void 0;
          if (c) {
            const l = E(c);
            return this._emitEvent({
              type: "body",
              key: s,
              data: l
            }), r.onFetched(l), l ? y(l) : i(h);
          }
          return i(void 0);
        }
      });
      try {
        return await I(g, {
          // infinite retries
          ...r.retryOptions,
          retries: -1,
          shouldSkip: q(r.skipRetryErrorCodes),
          onRetry: async (o, b) => {
            u == null || u.service(v, 5, `Retry ${b}`, s), n && await n(r, o);
          }
        }), i(void 0);
      } catch (o) {
        if (typeof o == "object" && o) {
          const w = (p = o.error) == null ? void 0 : p.body;
          if (w && w instanceof ReadableStream) {
            const d = await S(w);
            if (d) {
              const _ = E(d);
              o.error = _;
            }
          }
        }
        return this.updateStatus(s, "closed"), this._emitEvent({
          type: "error",
          key: s,
          data: o
        }), D.report(o), this.close(s), r.onError && await r.onError(r, o), t ? (await t(r, o), await this.fetch(r)) : i(o);
      }
    });
  }
};
export {
  Z as braidService,
  U as buildQueryString,
  Y as initConfig,
  q as isErrorCode,
  u as logger,
  L as setIntervalWithTimeout
};
