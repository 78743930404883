const t = /* @__PURE__ */ (() => {
  let o = { console: !0 };
  return {
    init: (e) => {
      o = { ...o, ...e };
    },
    report: (e, r = "error") => {
      o.log && o.log(e), console[r](e);
    }
  };
})();
export {
  t as errorService
};
