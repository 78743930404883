/* eslint-disable @typescript-eslint/no-explicit-any */
import posthog, { PostHogConfig } from 'posthog-js';
import { envs } from '../envs';

const posthogKey = envs.VITE_POSTHOG_KEY;
export const enabled = !!posthogKey;

export const init = (options?: Partial<PostHogConfig>) => {
  if (!enabled) return;
  return posthog.init(posthogKey, {
    api_host: 'https://app.posthog.com',
    session_recording: {
      maskAllInputs: true,
      maskTextSelector: '*',
    },
    opt_out_capturing_by_default: true,
    autocapture: true,
    ...options,
  });
};
