import { SessionAccessRuleStatus as J } from "@waitroom/models";
import { aiApiService as de, buildSuccessRequestResponse as X, calendarApiService as be, sessionApiService as H, cmsApiService as Te, integrationsApiService as ce, meetingMemoryApiService as ye, recordingApiService as Ke, sessionAccessApiService as ee, userApiService as Ee, authApiService as Ie, subscriptionPlanApiService as Ge, tokenApiService as Be, userMeetingTypesService as we } from "@waitroom/common-api";
import { applyPatch as Le, deepClone as L } from "fast-json-patch";
import { errorService as ke } from "@waitroom/error-service";
import { useInfiniteQuery as Ue, useQuery as xe, useQueryClient as We, QueryObserver as De } from "@tanstack/react-query";
import { useEffect as x, useRef as Ve, useState as Me, useCallback as je } from "react";
var oe = { exports: {} }, ze = oe.exports, ve;
function Je() {
  return ve || (ve = 1, function(e, t) {
    (function(n, s) {
      e.exports = s();
    })(ze, function() {
      var n = 1e3, s = 6e4, r = 36e5, o = "millisecond", i = "second", u = "minute", f = "hour", p = "day", S = "week", l = "month", I = "quarter", P = "year", N = "date", Ae = "Invalid Date", Ye = /^(\d{4})[-/]?(\d{1,2})?[-/]?(\d{0,2})[Tt\s]*(\d{1,2})?:?(\d{1,2})?:?(\d{1,2})?[.:]?(\d+)?$/, Ne = /\[([^\]]+)]|Y{1,4}|M{1,4}|D{1,2}|d{1,4}|H{1,2}|h{1,2}|a|A|m{1,2}|s{1,2}|Z{1,2}|SSS/g, qe = { name: "en", weekdays: "Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday".split("_"), months: "January_February_March_April_May_June_July_August_September_October_November_December".split("_"), ordinal: function(g) {
        var y = ["th", "st", "nd", "rd"], a = g % 100;
        return "[" + g + (y[(a - 20) % 10] || y[a] || y[0]) + "]";
      } }, ge = function(g, y, a) {
        var E = String(g);
        return !E || E.length >= y ? g : "" + Array(y + 1 - E.length).join(a) + g;
      }, He = { s: ge, z: function(g) {
        var y = -g.utcOffset(), a = Math.abs(y), E = Math.floor(a / 60), c = a % 60;
        return (y <= 0 ? "+" : "-") + ge(E, 2, "0") + ":" + ge(c, 2, "0");
      }, m: function g(y, a) {
        if (y.date() < a.date()) return -g(a, y);
        var E = 12 * (a.year() - y.year()) + (a.month() - y.month()), c = y.clone().add(E, l), m = a - c < 0, _ = y.clone().add(E + (m ? -1 : 1), l);
        return +(-(E + (a - c) / (m ? c - _ : _ - c)) || 0);
      }, a: function(g) {
        return g < 0 ? Math.ceil(g) || 0 : Math.floor(g);
      }, p: function(g) {
        return { M: l, y: P, w: S, d: p, D: N, h: f, m: u, s: i, ms: o, Q: I }[g] || String(g || "").toLowerCase().replace(/s$/, "");
      }, u: function(g) {
        return g === void 0;
      } }, V = "en", G = {};
      G[V] = qe;
      var Oe = "$isDayjsObject", me = function(g) {
        return g instanceof se || !(!g || !g[Oe]);
      }, te = function g(y, a, E) {
        var c;
        if (!y) return V;
        if (typeof y == "string") {
          var m = y.toLowerCase();
          G[m] && (c = m), a && (G[m] = a, c = m);
          var _ = y.split("-");
          if (!c && _.length > 1) return g(_[0]);
        } else {
          var A = y.name;
          G[A] = y, c = A;
        }
        return !E && c && (V = c), c || !E && V;
      }, R = function(g, y) {
        if (me(g)) return g.clone();
        var a = typeof y == "object" ? y : {};
        return a.date = g, a.args = arguments, new se(a);
      }, C = He;
      C.l = te, C.i = me, C.w = function(g, y) {
        return R(g, { locale: y.$L, utc: y.$u, x: y.$x, $offset: y.$offset });
      };
      var se = function() {
        function g(a) {
          this.$L = te(a.locale, null, !0), this.parse(a), this.$x = this.$x || a.x || {}, this[Oe] = !0;
        }
        var y = g.prototype;
        return y.parse = function(a) {
          this.$d = function(E) {
            var c = E.date, m = E.utc;
            if (c === null) return /* @__PURE__ */ new Date(NaN);
            if (C.u(c)) return /* @__PURE__ */ new Date();
            if (c instanceof Date) return new Date(c);
            if (typeof c == "string" && !/Z$/i.test(c)) {
              var _ = c.match(Ye);
              if (_) {
                var A = _[2] - 1 || 0, O = (_[7] || "0").substring(0, 3);
                return m ? new Date(Date.UTC(_[1], A, _[3] || 1, _[4] || 0, _[5] || 0, _[6] || 0, O)) : new Date(_[1], A, _[3] || 1, _[4] || 0, _[5] || 0, _[6] || 0, O);
              }
            }
            return new Date(c);
          }(a), this.init();
        }, y.init = function() {
          var a = this.$d;
          this.$y = a.getFullYear(), this.$M = a.getMonth(), this.$D = a.getDate(), this.$W = a.getDay(), this.$H = a.getHours(), this.$m = a.getMinutes(), this.$s = a.getSeconds(), this.$ms = a.getMilliseconds();
        }, y.$utils = function() {
          return C;
        }, y.isValid = function() {
          return this.$d.toString() !== Ae;
        }, y.isSame = function(a, E) {
          var c = R(a);
          return this.startOf(E) <= c && c <= this.endOf(E);
        }, y.isAfter = function(a, E) {
          return R(a) < this.startOf(E);
        }, y.isBefore = function(a, E) {
          return this.endOf(E) < R(a);
        }, y.$g = function(a, E, c) {
          return C.u(a) ? this[E] : this.set(c, a);
        }, y.unix = function() {
          return Math.floor(this.valueOf() / 1e3);
        }, y.valueOf = function() {
          return this.$d.getTime();
        }, y.startOf = function(a, E) {
          var c = this, m = !!C.u(E) || E, _ = C.p(a), A = function(w, $) {
            var q = C.w(c.$u ? Date.UTC(c.$y, $, w) : new Date(c.$y, $, w), c);
            return m ? q : q.endOf(p);
          }, O = function(w, $) {
            return C.w(c.toDate()[w].apply(c.toDate("s"), (m ? [0, 0, 0, 0] : [23, 59, 59, 999]).slice($)), c);
          }, D = this.$W, v = this.$M, Q = this.$D, k = "set" + (this.$u ? "UTC" : "");
          switch (_) {
            case P:
              return m ? A(1, 0) : A(31, 11);
            case l:
              return m ? A(1, v) : A(0, v + 1);
            case S:
              var B = this.$locale().weekStart || 0, j = (D < B ? D + 7 : D) - B;
              return A(m ? Q - j : Q + (6 - j), v);
            case p:
            case N:
              return O(k + "Hours", 0);
            case f:
              return O(k + "Minutes", 1);
            case u:
              return O(k + "Seconds", 2);
            case i:
              return O(k + "Milliseconds", 3);
            default:
              return this.clone();
          }
        }, y.endOf = function(a) {
          return this.startOf(a, !1);
        }, y.$set = function(a, E) {
          var c, m = C.p(a), _ = "set" + (this.$u ? "UTC" : ""), A = (c = {}, c[p] = _ + "Date", c[N] = _ + "Date", c[l] = _ + "Month", c[P] = _ + "FullYear", c[f] = _ + "Hours", c[u] = _ + "Minutes", c[i] = _ + "Seconds", c[o] = _ + "Milliseconds", c)[m], O = m === p ? this.$D + (E - this.$W) : E;
          if (m === l || m === P) {
            var D = this.clone().set(N, 1);
            D.$d[A](O), D.init(), this.$d = D.set(N, Math.min(this.$D, D.daysInMonth())).$d;
          } else A && this.$d[A](O);
          return this.init(), this;
        }, y.set = function(a, E) {
          return this.clone().$set(a, E);
        }, y.get = function(a) {
          return this[C.p(a)]();
        }, y.add = function(a, E) {
          var c, m = this;
          a = Number(a);
          var _ = C.p(E), A = function(v) {
            var Q = R(m);
            return C.w(Q.date(Q.date() + Math.round(v * a)), m);
          };
          if (_ === l) return this.set(l, this.$M + a);
          if (_ === P) return this.set(P, this.$y + a);
          if (_ === p) return A(1);
          if (_ === S) return A(7);
          var O = (c = {}, c[u] = s, c[f] = r, c[i] = n, c)[_] || 1, D = this.$d.getTime() + a * O;
          return C.w(D, this);
        }, y.subtract = function(a, E) {
          return this.add(-1 * a, E);
        }, y.format = function(a) {
          var E = this, c = this.$locale();
          if (!this.isValid()) return c.invalidDate || Ae;
          var m = a || "YYYY-MM-DDTHH:mm:ssZ", _ = C.z(this), A = this.$H, O = this.$m, D = this.$M, v = c.weekdays, Q = c.months, k = c.meridiem, B = function($, q, z, ne) {
            return $ && ($[q] || $(E, m)) || z[q].slice(0, ne);
          }, j = function($) {
            return C.s(A % 12 || 12, $, "0");
          }, w = k || function($, q, z) {
            var ne = $ < 12 ? "AM" : "PM";
            return z ? ne.toLowerCase() : ne;
          };
          return m.replace(Ne, function($, q) {
            return q || function(z) {
              switch (z) {
                case "YY":
                  return String(E.$y).slice(-2);
                case "YYYY":
                  return C.s(E.$y, 4, "0");
                case "M":
                  return D + 1;
                case "MM":
                  return C.s(D + 1, 2, "0");
                case "MMM":
                  return B(c.monthsShort, D, Q, 3);
                case "MMMM":
                  return B(Q, D);
                case "D":
                  return E.$D;
                case "DD":
                  return C.s(E.$D, 2, "0");
                case "d":
                  return String(E.$W);
                case "dd":
                  return B(c.weekdaysMin, E.$W, v, 2);
                case "ddd":
                  return B(c.weekdaysShort, E.$W, v, 3);
                case "dddd":
                  return v[E.$W];
                case "H":
                  return String(A);
                case "HH":
                  return C.s(A, 2, "0");
                case "h":
                  return j(1);
                case "hh":
                  return j(2);
                case "a":
                  return w(A, O, !0);
                case "A":
                  return w(A, O, !1);
                case "m":
                  return String(O);
                case "mm":
                  return C.s(O, 2, "0");
                case "s":
                  return String(E.$s);
                case "ss":
                  return C.s(E.$s, 2, "0");
                case "SSS":
                  return C.s(E.$ms, 3, "0");
                case "Z":
                  return _;
              }
              return null;
            }($) || _.replace(":", "");
          });
        }, y.utcOffset = function() {
          return 15 * -Math.round(this.$d.getTimezoneOffset() / 15);
        }, y.diff = function(a, E, c) {
          var m, _ = this, A = C.p(E), O = R(a), D = (O.utcOffset() - this.utcOffset()) * s, v = this - O, Q = function() {
            return C.m(_, O);
          };
          switch (A) {
            case P:
              m = Q() / 12;
              break;
            case l:
              m = Q();
              break;
            case I:
              m = Q() / 3;
              break;
            case S:
              m = (v - D) / 6048e5;
              break;
            case p:
              m = (v - D) / 864e5;
              break;
            case f:
              m = v / r;
              break;
            case u:
              m = v / s;
              break;
            case i:
              m = v / n;
              break;
            default:
              m = v;
          }
          return c ? m : C.a(m);
        }, y.daysInMonth = function() {
          return this.endOf(l).$D;
        }, y.$locale = function() {
          return G[this.$L];
        }, y.locale = function(a, E) {
          if (!a) return this.$L;
          var c = this.clone(), m = te(a, E, !0);
          return m && (c.$L = m), c;
        }, y.clone = function() {
          return C.w(this.$d, this);
        }, y.toDate = function() {
          return new Date(this.valueOf());
        }, y.toJSON = function() {
          return this.isValid() ? this.toISOString() : null;
        }, y.toISOString = function() {
          return this.$d.toISOString();
        }, y.toString = function() {
          return this.$d.toUTCString();
        }, g;
      }(), Re = se.prototype;
      return R.prototype = Re, [["$ms", o], ["$s", i], ["$m", u], ["$H", f], ["$W", p], ["$M", l], ["$y", P], ["$D", N]].forEach(function(g) {
        Re[g[1]] = function(y) {
          return this.$g(y, g[0], g[1]);
        };
      }), R.extend = function(g, y) {
        return g.$i || (g(y, se, R), g.$i = !0), R;
      }, R.locale = te, R.isDayjs = me, R.unix = function(g) {
        return R(1e3 * g);
      }, R.en = G[V], R.Ls = G, R.p = {}, R;
    });
  }(oe)), oe.exports;
}
Je();
const $e = (e) => typeof e == "function", Z = ({
  client: e,
  key: t,
  fn: n,
  skipEmpty: s
}) => {
  const r = e.getQueryData(t);
  s && r === void 0 || e.setQueryData(t, n(r));
}, re = (e) => Z({
  ...e,
  fn: (t) => ({
    code: 200,
    success: !0,
    ...t,
    data: $e(e.dataOrFn) ? e.dataOrFn(t == null ? void 0 : t.data) : e.dataOrFn
  })
}), Ze = {
  update: re,
  addToArray: (e) => re({
    ...e,
    dataOrFn: (t) => [
      ...Array.isArray(e.data) ? e.data : [e.data],
      ...t || []
    ]
  }),
  updateInArray: ({
    data: e,
    keyId: t,
    ...n
  }) => re({
    ...n,
    dataOrFn: (s) => s == null ? void 0 : s.map((r) => r[t] === e[t] ? e : r)
  }),
  removeFromArray: ({
    id: e,
    keyId: t,
    ...n
  }) => re({
    ...n,
    dataOrFn: (s) => s == null ? void 0 : s.filter((r) => r[t] !== e)
  }),
  addToPages: (e) => Z({
    ...e,
    fn: (t) => {
      var s, r, o;
      const n = {
        pageParams: [...(t == null ? void 0 : t.pageParams) || []],
        pages: [...(t == null ? void 0 : t.pages) || []]
      };
      return n.pages[0] = {
        ...n.pages[0],
        data: {
          code: 200,
          success: !0,
          ...(s = n.pages[0]) == null ? void 0 : s.data,
          data: e.updateFn((o = (r = n.pages[0]) == null ? void 0 : r.data) == null ? void 0 : o.data)
        }
      }, n;
    }
  }),
  updateInPages: (e) => Z({
    ...e,
    fn: (t) => {
      var s, r;
      if (!t || !((s = t.pages) != null && s.length)) return t;
      const n = {
        pageParams: [...t.pageParams],
        pages: [...t.pages]
      };
      for (let o = 0; o < n.pages.length; o++) {
        const i = (r = n.pages[o]) == null ? void 0 : r.data, u = i == null ? void 0 : i.data;
        if (!u) continue;
        const f = e.updateFn(u);
        if (i !== f && (n.pages[o] = {
          ...n.pages[o],
          data: {
            ...i,
            data: f
          }
        }, e.stopOnChange))
          break;
      }
      return n;
    }
  })
}, Xe = (e) => Z({
  ...e,
  fn: (t) => {
    var n;
    return {
      code: 200,
      success: !0,
      ...t,
      data: {
        code: 200,
        success: !0,
        ...t == null ? void 0 : t.data,
        data: $e(e.dataOrFn) ? e.dataOrFn((n = t == null ? void 0 : t.data) == null ? void 0 : n.data) : e.dataOrFn
      }
    };
  }
}), et = {
  update: Xe
}, h = {
  update: Z,
  request: Ze,
  apiRequest: et
}, le = "private", he = "calendarEvent", tt = "cmsPage", st = "cmsPages", ts = "currentUser", nt = "dashboardPastSessions", rt = "dashboardSessions", ss = "featuredSessions", ns = "integrationsConnections", rs = "integrationsDirectURL", os = "integrationsGetAction", ot = "integrationsProviders", it = "meetingMemoryMessages", is = "meetingMemorySuggestions", ut = "meetingMemoryThread", at = "meetingMemoryThreads", ct = "paymentDetails", yt = "recording", us = "recordingStatus", Et = "session", as = "sessionAccessRule", _e = "sessionAccessRules", ft = "sessionAiFeed", gt = "sessionEndSummary", mt = "sessionEpisodes", cs = "sessionPendingRulesStats", _t = "sessionPresence", Fe = "sessionRequestAccess", ys = "sessionViewers", St = "sessionCards", Pe = "streamToken", dt = "listeningModeSpeakers", lt = "subscriptionPlans", pt = "subscriptionReceipts", Ct = "user", At = "integrationsGetByOwnerSessionRecurrence", Ot = "userMeetingTypes-v2", Es = "salesforceProvider", fs = "salesforceGetBindingById", gs = "salesforceGetAccountById", ms = "salesforceGetOpportunityById", _s = "salesforceListBindableRecordsAsOptions", Ss = "salesforceGetContacts", ds = "salesforceGetLeadById", ls = "hubspotProvider", ps = "hubspotGetBindingById", Rt = "hubspotGetCompanyById", Cs = "hubspotGetDealById", As = "hubspotListBindableRecordsAsOptions", Os = "hubspotGetContacts", Rs = "hubspotGetContactById", Ds = (e, t) => [le, ...e, t], ie = (e, t = "") => [
  ft,
  e,
  t
], Se = (e, t = "") => [
  Fe,
  e,
  t,
  J.IN_REVIEW
], Dt = (e, t, n) => [Fe, e, t, n || ""], Mt = (e, t, n) => [Et, e, t || "", n || ""], vt = (e, t = "") => [
  _t,
  e,
  t
], Tt = (e) => [Ct, e], pe = (e) => [
  at,
  e
], Ms = (e) => [ut, e], U = (e) => [
  it,
  e
], vs = (e, t) => [
  dt,
  e,
  t
], Kt = (e, t) => [
  gt,
  e,
  t
], $t = {
  add: ({ client: e, data: t, sessionId: n, recurrenceId: s }) => {
    t && h.request.addToArray({
      client: e,
      key: ie(n, s),
      data: [t]
    });
  },
  update: ({ client: e, data: t, sessionId: n, recurrenceId: s }) => {
    t && h.request.update({
      client: e,
      key: ie(n, s),
      dataOrFn: (r) => r ? (r.messages = r.messages.map((o) => o.id === t.id ? t : o), r) : { messages: [t], totalSummaryCount: 1 }
    });
  },
  remove: ({ client: e, sessionId: t, recurrenceId: n, id: s }) => {
    h.request.removeFromArray({
      client: e,
      key: ie(t, n),
      id: s,
      keyId: "id"
    });
  }
}, Ts = {
  ...$t
}, ht = {
  addMessage: ({ client: e, data: t, id: n }) => {
    t && h.apiRequest.update({
      client: e,
      key: U(n),
      dataOrFn: (s) => ({
        hasMore: !1,
        ...s,
        messages: [...(s == null ? void 0 : s.messages) || [], t],
        total: ((s == null ? void 0 : s.total) || 0) + 1
      })
    });
  },
  updateMessage: ({ client: e, data: t, id: n }) => {
    t && h.apiRequest.update({
      client: e,
      key: U(n),
      dataOrFn: (s) => s != null && s.messages ? {
        ...s,
        messages: s.messages.map((r) => r.id === t.id ? { ...r, ...t } : r)
      } : s
    });
  },
  updateLastAiMessage: ({ client: e, data: t, id: n }) => {
    t && h.apiRequest.update({
      client: e,
      key: U(n),
      dataOrFn: (s) => {
        var f;
        const r = s && {
          ...s,
          messages: s.messages ? [...s.messages] : void 0
        };
        if (!(r != null && r.messages)) return s;
        const o = r.messages.findLastIndex(
          (p) => (!t.id || p.id === t.id) && p.role === "ai"
        );
        if (o === -1) return s;
        const i = r.messages[o], u = { ...i, ...t, id: t.id || i.id };
        return t.content && (u.content = `${i.content || ""}${t.content}`), t.todo && (u.todo = `${i.todo || ""}${t.todo}`), t.progress && (u.progress = `${i.progress || ""}${t.progress}`), r.messages[o] = u, !((f = u.content) != null && f.length) && t.isComplete && r.messages.splice(o, 1), r;
      }
    });
  },
  removeMessage: ({ client: e, id: t, messageId: n }) => {
    h.apiRequest.update({
      client: e,
      key: U(t),
      dataOrFn: (s) => {
        var i;
        if (!(s != null && s.messages)) return s;
        const r = s.messages.length, o = (i = s.messages) == null ? void 0 : i.filter((u) => u.id !== n);
        return {
          ...s,
          messages: o,
          total: Math.max((s.total || 0) - (r !== o.length ? 1 : 0), 0)
        };
      }
    });
  },
  removeLastMessage: ({ client: e, id: t }) => {
    h.apiRequest.update({
      client: e,
      key: U(t),
      dataOrFn: (n) => n != null && n.messages ? {
        ...n,
        messages: n.messages.slice(0, -1),
        total: Math.max((n.total || 0) - 1, 0)
      } : n
    });
  },
  removeThread: ({ client: e, id: t, userId: n }) => {
    h.apiRequest.update({
      client: e,
      key: pe(n),
      dataOrFn: (s) => {
        var i;
        if (!(s != null && s.threads)) return s;
        const r = s.threads.length, o = (i = s.threads) == null ? void 0 : i.filter((u) => u.id !== t);
        return {
          ...s,
          threads: o,
          total: Math.max((s.total || 0) - (r !== o.length ? 1 : 0), 0)
        };
      }
    });
  }
}, Ks = ht;
let ue;
const $s = (e) => {
  ue = e.logger;
}, Ft = (e) => {
  var t;
  return (t = e == null ? void 0 : e.data) == null ? void 0 : t.data;
}, hs = (e) => Ft(e.data), Fs = (e, t = 0) => {
  var n, s, r;
  return (r = (s = (n = e.data) == null ? void 0 : n.pages[t]) == null ? void 0 : s.data) == null ? void 0 : r.data;
}, W = (e, t, n = void 0, ...s) => {
  try {
    return Le(e, t, ...s).newDocument;
  } catch (r) {
    return ue == null || ue.logWith(3, r, e, t), ke.report(r), n;
  }
}, Ps = (e, t, n) => {
  if (e.op !== "add" || !e.path.endsWith("/-")) return !0;
  const s = e.path.split("/").slice(1, -1);
  let r = n;
  for (const o of s)
    r[o] === null && (r[o] = []), r = r[o];
  return !0;
}, Qs = {
  refetchOnMount: !1,
  refetchOnReconnect: !1,
  refetchOnWindowFocus: !1
}, Ce = (e) => (t) => ({
  gcTime: t,
  staleTime: t,
  retry: 2,
  refetchOnMount: !0,
  refetchOnReconnect: e,
  refetchOnWindowFocus: e
}), d = {
  "7d": 6048e5,
  "3d": 2592e5,
  "1d": 864e5,
  "6h": 216e5,
  "1h": 36e5,
  "30m": 18e5,
  "15m": 9e5,
  "10m": 6e5,
  "5m": 3e5,
  "3m": 18e4,
  "1m": 6e4,
  "30s": 3e4,
  "15s": 15e3,
  "5s": 5e3,
  "3s": 3e3,
  0: 0
}, M = [""], Y = () => {
}, T = Ce(!0), fe = {
  "7d": T(d["7d"]),
  "3d": T(d["3d"]),
  "1d": T(d["1d"]),
  "6h": T(d["6h"]),
  "1h": T(d["1h"]),
  "30m": T(d["30m"]),
  "15m": T(d["15m"]),
  "10m": T(d["10m"]),
  "5m": T(d["5m"]),
  "3m": T(d["3m"]),
  "1m": T(d["1m"]),
  "30s": T(d["30s"]),
  "15s": T(d["15s"]),
  "3s": T(d["3s"]),
  0: T(0)
}, K = Ce(!1), F = {
  "7d": K(d["7d"]),
  "3d": K(d["3d"]),
  "1d": K(d["1d"]),
  "6h": K(d["6h"]),
  "1h": K(d["1h"]),
  "30m": K(d["30m"]),
  "15m": K(d["15m"]),
  "10m": K(d["10m"]),
  "5m": K(d["5m"]),
  "3m": K(d["3m"]),
  "1m": K(d["1m"]),
  "30s": K(d["30s"]),
  "15s": K(d["15s"]),
  "3s": K(d["3s"]),
  0: K(0)
}, b = (e) => ({
  ...Ce(!1)(e),
  refetchOnReconnect: !0
}), Ys = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: n,
  onFetched: s,
  onPatch: r,
  options: o,
  params: i
}) => {
  const u = !!e, f = u ? ie(e, t) : M;
  return {
    ...b(d[0]),
    ...o,
    queryKey: f,
    queryFn: u ? ({ signal: p }) => de.getFeed(e, t, {
      ...i,
      signal: p,
      params: {
        limit: 200,
        sortOrder: "asc",
        ...i == null ? void 0 : i.params
      },
      onFetched: (S) => {
        n.setQueryData(f, S), s && s(S);
      },
      onPatch: (S) => {
        r && r(S), n.setQueryData(
          f,
          (l = X({
            messages: [],
            totalSummaryCount: 0
          })) => W(L(l), S) ?? l
        );
      }
    }) : Y
  };
}, Ns = ({
  sessionId: e,
  recurrenceId: t,
  options: n,
  params: s
}) => ({
  ...n,
  mutationFn: (r) => de.event(e, t, {
    ...s,
    data: r
  })
}), qs = ({
  sessionId: e,
  recurrenceId: t
}) => ({
  queryKey: Kt(e, t),
  queryFn: () => de.getSummary(e, t),
  ...F["5m"]
}), Hs = (e) => [he, e], bs = (e) => ({
  queryKey: [he, e],
  queryFn: () => be.getCalndrLinkEvent({ data: e })
}), Pt = (e, t) => [
  St,
  e,
  t
], Is = (e, t, n, s, r) => {
  const o = !!e && !!t, i = o ? Pt(e, t) : M;
  return {
    queryKey: i,
    enabled: o,
    ...b(d[0]),
    queryFn: o ? () => H.cards(e, t, {
      onFetched: (u) => {
        n.setQueryData(i, u);
      },
      onPatch: (u) => {
        n.setQueryData(
          i,
          (f = {}) => W(L(f), u) ?? f
        );
      },
      ...s
    }) : Y,
    ...r
  };
}, Qt = (e) => [tt, e], Gs = ({
  slug: e,
  isPreview: t
}) => ({
  queryKey: e ? Qt(e) : M,
  queryFn: () => Te.getPage(e || ""),
  ...F[t ? 0 : "5m"],
  enabled: !!e
}), Yt = (e) => [st, e], Bs = ({
  types: e,
  isPreview: t
}) => ({
  queryKey: Yt(e),
  queryFn: async () => {
    var o;
    const n = await Te.getPages(e);
    if (!((o = n == null ? void 0 : n.data) != null && o.result)) throw n;
    const r = n.data.result.reduce(
      (i, u) => u.type ? u.type === "general" ? (u.category && (i.general[u.category] = [...i[u.type][u.category] || [], u]), i) : (i[u.type] = [...i[u.type] || [], u], i) : i,
      {
        general: {
          // here because of the order
          features: [],
          solutions: [],
          compare: [],
          resources: [],
          product: [],
          none: []
        }
      }
    );
    return {
      ...n,
      grouped: r
    };
  },
  ...F[t ? 0 : "15m"],
  gcTime: t ? 0 : 864e5
  // 1 day
}), Nt = (e, t) => [At, e, t], ws = ({
  sessionId: e,
  recurrenceId: t,
  queryClient: n,
  options: s,
  params: r
}) => {
  const o = !!e && !!t, i = o ? Nt(e, t) : M;
  return {
    queryKey: i,
    enabled: o,
    ...b(d[0]),
    ...s,
    queryFn: o ? () => ce.getBindingsByOwnerSessionRecurrencePerProvider(
      e,
      t,
      {
        onFetched: (u) => {
          n.setQueryData(i, u);
        },
        onPatch: (u) => {
          n.setQueryData(
            i,
            (f = {}) => W(L(f), u) ?? f
          );
        },
        ...r
      }
    ) : Y
  };
}, Ls = ({
  params: e,
  ...t
}) => ({
  ...t,
  mutationFn: ({
    sessionId: n,
    sessionRecurrenceId: s,
    record: { id: r, type: o }
  }) => ce.hubspot.createBinding({
    ...e,
    data: {
      sessionId: n,
      sessionRecurrenceId: s,
      record: {
        id: r,
        type: o
      }
    }
  })
}), qt = (e) => [Rt, e], ks = ({
  id: e,
  params: t,
  ...n
}) => {
  const s = !!e;
  return {
    ...F["1m"],
    ...n,
    enabled: s,
    queryKey: s ? qt(e) : M,
    queryFn: s ? () => ce.hubspot.getCompanyById(e) : Y
  };
}, Ht = [ot], Us = {
  queryKey: Ht,
  queryFn: ce.getProviders,
  ...F["1h"]
}, xs = ({
  userId: e,
  options: t,
  params: n
}) => {
  const s = !!e, r = s ? pe(e) : M;
  return {
    ...fe["3m"],
    ...t,
    queryKey: r,
    queryFn: s ? () => ye.threads(n) : Y
  };
}, Ws = ({
  id: e,
  options: t,
  params: n
}) => {
  const s = !!e, r = s ? pe(e) : M;
  return {
    ...F["3m"],
    ...t,
    queryKey: r,
    enabled: s,
    queryFn: s ? () => ye.thread(e, n) : Y
  };
}, Vs = ({
  id: e,
  options: t,
  params: n,
  onFetch: s
}) => {
  const r = !!e, o = r ? U(e) : M;
  return {
    ...F["3m"],
    queryKey: o,
    enabled: r,
    ...t,
    queryFn: r ? () => (s == null || s(), ye.threadMessages(e, n)) : Y
  };
}, js = ({
  id: e,
  options: t,
  params: n
}) => ({
  ...t,
  mutationFn: (s) => ye.deleteThread(e, {
    ...n,
    data: s
  })
}), bt = (e) => [
  mt,
  e
], zs = (e) => ({
  queryKey: e ? bt(e) : M,
  queryFn: e ? () => Ke.getEpisodes(e) : Y,
  ...F["5m"],
  enabled: !!e
}), It = (e, t) => [
  yt,
  e,
  t
], Js = (e, t) => {
  const n = !!e && !!t;
  return {
    queryKey: n ? It(e, t) : M,
    ...F["15m"],
    queryFn: n ? () => Ke.getRecording(e, t) : Y,
    enabled: n
  };
}, Zs = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: n,
  onPatch: s,
  options: r,
  params: o
}) => {
  const i = !!e, u = i ? Se(e, t) : M;
  return {
    ...b(d[0]),
    ...r,
    queryKey: u,
    enabled: i,
    queryFn: ({ signal: f }) => ee.inReview({
      ...o,
      signal: f,
      params: {
        ...o == null ? void 0 : o.params,
        sessionID: e || "",
        sessionRecurrenceID: t
      },
      onFetched: (p) => {
        n.setQueryData(u, p);
      },
      onPatch: (p) => {
        s && s(p), n.setQueryData(
          u,
          (S = X(
            void 0
          )) => W(L(S), p) ?? S
        );
      }
    })
  };
}, Xs = ({
  userId: e,
  sessionId: t,
  recurrenceId: n = "",
  queryClient: s,
  onPatch: r,
  options: o,
  params: i
}) => {
  const u = !!e && !!t, f = u ? Dt(e, t, n) : M;
  return {
    ...b(d[0]),
    ...o,
    queryKey: f,
    enabled: u,
    queryFn: u ? ({ signal: p }) => ee.userSessionRequest({
      ...i,
      signal: p,
      params: {
        ...i == null ? void 0 : i.params,
        sessionID: t,
        ...n ? { sessionRecurrenceID: n } : {}
      },
      onFetched: (S) => {
        s.setQueryData(f, S);
      },
      onPatch: (S) => {
        r && r(S), s.setQueryData(
          f,
          (l = X(
            void 0
          )) => W(L(l), L(S)) ?? l
        );
      }
    }) : Y
  };
}, en = ({
  options: e,
  params: t
} = {}) => ({
  ...e,
  mutationFn: (n) => ee.requestAccess({
    ...t,
    data: n
  })
}), tn = ({
  options: e,
  params: t
}) => ({
  ...e,
  mutationFn: (n) => ee.update({
    ...t,
    data: n
  })
}), Gt = (e, t) => [
  _e,
  e,
  t,
  J.GRANTED
], sn = (e, t, n) => ({
  queryKey: Gt(e, t),
  queryFn: () => ee.getViewer({
    params: {
      limit: 50,
      ...n,
      sessionID: e,
      sessionRecurrenceID: t,
      restrictionStatus: J.GRANTED
    }
  }),
  ...fe["3m"]
}), nn = ({
  sessionId: e,
  recurrenceId: t = "",
  userId: n,
  onFetched: s,
  onPatch: r,
  onError: o,
  options: i,
  params: u,
  queryClient: f
}) => {
  const p = e && n ? Mt(e, t, n) : M;
  return {
    ...b(d[0]),
    // force no caching because of problems with redux sync
    initialData: void 0,
    retry: (S, l) => (l == null ? void 0 : l.code) !== 401,
    enabled: !!e && !!n,
    ...i,
    queryKey: p,
    queryFn: e ? ({ signal: S }) => H.getById(e, {
      ...u,
      signal: S,
      onFetched: (l) => {
        f.setQueryData(p, l), s && s(l);
      },
      onPatch: r,
      onError: o
    }) : void 0
  };
}, rn = ({
  sessionId: e,
  recurrenceId: t = "",
  options: n,
  params: s
}) => ({
  ...n,
  mutationFn: (r) => H.update(e, t, {
    ...s,
    data: r
  })
}), on = ({
  sessionId: e
}) => ({
  mutationFn: () => H.end(e)
}), un = ({
  sessionId: e,
  recurrenceId: t = "",
  queryClient: n,
  onPatch: s,
  options: r,
  params: o
}) => {
  const i = e ? vt(e, t) : M;
  return {
    ...b(d["3s"]),
    enabled: !!e,
    ...r,
    queryKey: i,
    queryFn: e ? ({ signal: u }) => H.presence(e, {
      ...o,
      signal: u,
      onFetched: (f) => {
        n.setQueryData(i, f);
      },
      onPatch: (f) => {
        f && (s && s(f), n.setQueryData(
          i,
          (p = X(
            void 0
          )) => {
            const S = W(
              L(p),
              f
            );
            return S || n.refetchQueries({ queryKey: i }), S ?? p;
          }
        ));
      }
    }) : void 0
  };
}, an = ({
  sessionId: e,
  params: t,
  options: n
}) => ({
  ...n,
  mutationFn: (s) => H.updateParticipantMetadata(e, {
    ...t,
    data: s
  })
}), Qe = 50, ae = (e) => [
  le,
  rt,
  e
], cn = (e, {
  limit: t = Qe
} = {}) => ({
  queryKey: e ? ae(e) : M,
  queryFn: async ({ pageParam: n = 0 }) => H.getSessionsLibraryUpcoming({
    params: {
      limit: t,
      cursor: n * t
    }
  }),
  initialPageParam: 0,
  ...fe["15s"],
  getNextPageParam: (n, s) => s.length + 1,
  enabled: !!e
}), Bt = (e) => [
  le,
  nt,
  e
], yn = (e, {
  limit: t = Qe
} = {}) => ({
  queryKey: e ? Bt(e) : M,
  queryFn: async ({ pageParam: n = 0 }) => H.getSessionsLibraryHistory({
    params: {
      limit: t,
      cursor: n * t
    }
  }),
  initialPageParam: 0,
  ...fe["30s"],
  getNextPageParam: (n, s) => s.length + 1,
  enabled: !!e
}), En = ({
  userId: e,
  accessToken: t,
  params: n,
  ...s
}) => ({
  ...s,
  mutationFn: () => {
    if (!e || !t) throw new Error("Unauthorized");
    return Ee.activateTrial(e, {
      ...n,
      data: { token: t }
    });
  }
}), fn = ({ accessToken: e, params: t, ...n }) => ({
  ...n,
  mutationFn: () => Ie.hostOptIn({
    data: { accessToken: e },
    ...t
  })
}), wt = () => [lt], gn = () => ({
  queryKey: wt(),
  queryFn: Ge.getPlans,
  ...F["1h"]
}), mn = (e, t) => [
  Pe,
  e,
  t
], _n = (e, t) => {
  const n = !!e && !!t;
  return {
    queryKey: n ? [Pe, e, t] : M,
    queryFn: n ? () => Be.getStream(t) : void 0,
    enabled: n,
    ...F["5m"]
  };
}, Sn = ({
  id: e,
  options: t,
  params: n
}) => {
  const s = Tt(e);
  return {
    ...b(d[0]),
    ...t,
    queryKey: s,
    queryFn: ({ signal: r }) => Ee.getById(e, { ...n, signal: r })
  };
}, Lt = (e, t) => [
  pt,
  e,
  t
], dn = (e, t, n) => ({
  queryKey: Lt(e, t),
  queryFn: () => Ee.getTransactions(e, {
    params: { includeInvoices: "true", ...n }
  }),
  ...F["3m"]
}), kt = (e) => [ct, e], ln = (e, t) => {
  const n = !!e && !!t;
  return {
    queryKey: n ? kt(e) : M,
    queryFn: e ? () => Ee.getPaymentDetails(e || "") : Y,
    ...F["3m"],
    enabled: n
  };
}, Ut = () => [Ot], pn = () => ({
  queryKey: Ut(),
  queryFn: we.get,
  ...F["1h"]
}), xt = {
  add: ({ client: e, data: t, userId: n }) => {
    t && h.request.addToPages({
      client: e,
      key: ae(n),
      updateFn: (s) => ({
        ...s,
        count: ((s == null ? void 0 : s.count) || 0) + 1,
        sessions: [t, ...(s == null ? void 0 : s.sessions) || []]
      })
    });
  },
  update: ({ client: e, data: t, userId: n }) => {
    t && h.request.updateInPages({
      client: e,
      key: ae(n),
      stopOnChange: !0,
      updateFn: (s) => {
        if (!(s != null && s.sessions)) return s;
        const r = s.sessions.findIndex((i) => i.sessionID === t.sessionID);
        if (r === -1) return s;
        const o = {
          ...s,
          sessions: [...s.sessions]
        };
        return o.sessions[r] = t, o;
      }
    });
  },
  remove: ({ client: e, userId: t, id: n }) => {
    let s;
    return h.request.updateInPages({
      client: e,
      key: ae(t),
      stopOnChange: !0,
      updateFn: (r) => {
        if (!(r != null && r.sessions)) return r;
        const o = r.sessions.findIndex((u) => u.sessionID === n);
        if (o === -1) return r;
        const i = { ...r, sessions: [...r.sessions] };
        return i.count = Math.max(0, (i.count || 1) - 1), s = i.sessions.splice(o, 1)[0], i;
      }
    }), s;
  }
}, Wt = {
  add: ({ client: e, data: t = [] }) => {
    if (!t.length) return;
    const { sessionID: n, sessionRecurrenceID: s } = t[0];
    h.request.update({
      client: e,
      key: [
        _e,
        n,
        s,
        J.GRANTED
      ],
      dataOrFn: (r = {}) => {
        var l, I;
        const o = { ...r }, i = (P, N) => (N.type === "domain" ? P[0].push(N) : P[1].push(N), P), [u, f] = ((I = (l = o == null ? void 0 : o.data) == null ? void 0 : l.viewerAccessRules) == null ? void 0 : I.reduce(
          i,
          [[], []]
        )) || [], [p, S] = t.reduce(i, [
          [],
          []
        ]);
        return {
          data: {
            viewerAccessRules: [
              ...p,
              ...u || [],
              ...S,
              ...f || []
            ]
          }
        };
      }
    });
  },
  remove: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: n,
    id: s
  }) => {
    h.request.update({
      client: e,
      key: [
        _e,
        t,
        n,
        J.GRANTED
      ],
      dataOrFn: (r) => {
        var i;
        const o = ((i = r == null ? void 0 : r.data.viewerAccessRules) == null ? void 0 : i.filter((u) => u.id !== s)) || [];
        return {
          ...r,
          data: {
            ...r == null ? void 0 : r.data,
            viewerAccessRules: o
          }
        };
      }
    });
  },
  // requests
  addRequest: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: n,
    data: s
  }) => {
    var u;
    const r = Se(t, n), o = e.getQueryData(r) || X(void 0), i = {
      ...o,
      data: {
        ...o.data,
        accessRules: [...((u = o.data) == null ? void 0 : u.accessRules) || [], ...s]
      }
    };
    e.setQueryData(r, i);
  },
  removeRequest: ({
    client: e,
    sessionId: t,
    sessionRecurrenceId: n,
    id: s
  }) => {
    var S, l;
    const r = Se(t, n), o = e.getQueryData(r);
    if (!((S = o == null ? void 0 : o.data) != null && S.accessRules)) return;
    const i = o.data.accessRules, u = i == null ? void 0 : i.findIndex((I) => I.id === s);
    if (u === -1) return;
    const f = {
      ...o,
      data: {
        ...o.data,
        accessRules: [...((l = o.data) == null ? void 0 : l.accessRules) || []]
      }
    }, p = f.data.accessRules.splice(u, 1)[0];
    return e.setQueryData(r, f), p;
  }
}, Cn = {
  session: xt,
  viewerAccess: Wt
}, An = (e) => {
  const t = Ue(e), { onSuccess: n, onError: s } = e, { isError: r, isSuccess: o, error: i, data: u } = t;
  return x(() => {
    o && n && n(u);
  }, [u, o, n]), x(() => {
    r && s && s(i);
  }, [i, r, s]), t;
}, On = (e) => {
  const t = xe(e), { onSuccess: n, onError: s } = e, { isError: r, isSuccess: o, error: i, data: u } = t;
  return x(() => {
    o && n && n(u);
  }, [u, o, n]), x(() => {
    r && s && s(i);
  }, [i, r, s]), t;
}, Vt = {
  refetchOnMount: !1,
  refetchOnReconnect: !1,
  refetchOnWindowFocus: !1,
  refetchInterval: !1,
  useErrorBoundary: !1,
  enabled: !1
}, Rn = (e, t) => {
  const n = We(), s = {
    queryKey: e,
    ...Vt,
    ...t
  }, r = Ve(s);
  r.current = s;
  const [o, i] = Me(
    () => new De(n, s)
  ), [u, f] = Me(
    o == null ? void 0 : o.getOptimisticResult(s)
  );
  x(() => {
    if (!(e != null && e.length)) return;
    const S = new De(n, r.current);
    i(S);
    const l = S.getOptimisticResult(r.current);
    f(l);
  }, [n, e]), x(() => {
    const S = o.subscribe((l) => {
      f(l);
    });
    return () => {
      S();
    };
  }, [o]);
  const p = je(() => {
    const S = o.getOptimisticResult(r.current);
    f(S);
  }, [o]);
  return { ...u, refresh: p };
};
export {
  he as CACHE_KEY_CALENDAR_EVENT,
  tt as CACHE_KEY_CMS_PAGE,
  st as CACHE_KEY_CMS_PAGES,
  ts as CACHE_KEY_CURRENT_USER,
  nt as CACHE_KEY_DASHBOARD_PAST_SESSIONS,
  rt as CACHE_KEY_DASHBOARD_SESSIONS,
  ss as CACHE_KEY_FEATURED_SESSIONS,
  ps as CACHE_KEY_HUBSPOT_GET_BINDING_BY_ID,
  Rt as CACHE_KEY_HUBSPOT_GET_COMPANY_BY_ID,
  Os as CACHE_KEY_HUBSPOT_GET_CONTACTS,
  Rs as CACHE_KEY_HUBSPOT_GET_CONTACT_BY_ID,
  Cs as CACHE_KEY_HUBSPOT_GET_DEAL_BY_ID,
  As as CACHE_KEY_HUBSPOT_LIST_BINDABLE_RECORDS_AS_OPTIONS,
  ls as CACHE_KEY_HUBSPOT_PROVIDER,
  ns as CACHE_KEY_INTEGRATIONS_CONNECTIONS,
  rs as CACHE_KEY_INTEGRATIONS_DIRECT_URL,
  os as CACHE_KEY_INTEGRATIONS_GET_ACTION,
  At as CACHE_KEY_INTEGRATIONS_GET_BY_OWNER_SESSION_RECURRENCE,
  ot as CACHE_KEY_INTEGRATIONS_PROVIDERS,
  dt as CACHE_KEY_LISTENING_MODE_SPEAKERS,
  it as CACHE_KEY_MEETING_MEMORY_MESSAGES,
  is as CACHE_KEY_MEETING_MEMORY_SUGGESTIONS,
  ut as CACHE_KEY_MEETING_MEMORY_THREAD,
  at as CACHE_KEY_MEETING_MEMORY_THREADS,
  ct as CACHE_KEY_PAYMENT_DETAILS,
  le as CACHE_KEY_PRIVATE_BASE,
  yt as CACHE_KEY_RECORDING,
  us as CACHE_KEY_RECORDING_STATUS,
  gs as CACHE_KEY_SALESFORCE_GET_ACCOUNT_BY_ID,
  fs as CACHE_KEY_SALESFORCE_GET_BINDING_BY_ID,
  Ss as CACHE_KEY_SALESFORCE_GET_CONTACTS,
  ds as CACHE_KEY_SALESFORCE_GET_LEAD_BY_ID,
  ms as CACHE_KEY_SALESFORCE_GET_OPPORTUNITY_BY_ID,
  _s as CACHE_KEY_SALESFORCE_LIST_BINDABLE_RECORDS_AS_OPTIONS,
  Es as CACHE_KEY_SALESFORCE_PROVIDER,
  Et as CACHE_KEY_SESSION,
  as as CACHE_KEY_SESSION_ACCESS_RULE,
  _e as CACHE_KEY_SESSION_ACCESS_RULES,
  ft as CACHE_KEY_SESSION_AI_FEED,
  gt as CACHE_KEY_SESSION_END_SUMMARY,
  mt as CACHE_KEY_SESSION_EPISODES,
  St as CACHE_KEY_SESSION_GET_CARDS,
  cs as CACHE_KEY_SESSION_PENDING_RULES_STATS,
  _t as CACHE_KEY_SESSION_PRESENCE,
  Fe as CACHE_KEY_SESSION_REQUEST_ACCESS,
  ys as CACHE_KEY_SESSION_VIEWERS,
  Pe as CACHE_KEY_STREAM_TOKEN,
  lt as CACHE_KEY_SUBSCRIPTION_PLANS,
  pt as CACHE_KEY_SUBSCRIPTION_RECEIPTS,
  Ct as CACHE_KEY_USER,
  Ot as CACHE_KEY_USER_MEETING_TYPES,
  En as activateTrialMutation,
  fe as activeOptions,
  Ts as aiFeedCacheService,
  Ns as aiFeedEventMutation,
  b as baseBraidOptions,
  Ds as buildPrivateQueryKey,
  h as cacheService,
  bs as calendarEventQuery,
  Gs as cmsPageQuery,
  Bs as cmsPagesQuery,
  Ls as createCRMBindingMutation,
  Qs as disabledFetchOptions,
  M as emptyCacheKey,
  Y as emptyFn,
  on as endSessionMutation,
  T as getActiveOptions,
  ie as getAiFeedQueryKey,
  ws as getCRMProviderBindingsForSessionQuery,
  Nt as getCRMProviderBindingsForSessionQueryKey,
  Hs as getCalendarEventQueryKey,
  Is as getCardsQuery,
  Pt as getCardsQueryKey,
  Qt as getCmsPageQueryKey,
  Yt as getCmsPagesQueryKey,
  K as getInactiveOptions,
  Fs as getInfinityRequestData,
  Ht as getIntegrationsProvidersQueryKey,
  vs as getListeningModeSpeakersQueryKey,
  U as getMeetingMemoryThreadMessagesQueryKey,
  Ms as getMeetingMemoryThreadQueryKey,
  pe as getMeetingMemoryThreadsQueryKey,
  Bt as getPastSessionQueryKey,
  kt as getPaymentDetailsQueryKey,
  gn as getPlansQuery,
  wt as getPlansQueryKey,
  hs as getQueryRequestData,
  bt as getRecordingEpisodesQueryKey,
  It as getRecordingQueryKey,
  Ft as getRequestData,
  Ys as getSessionAiFeedQuery,
  Kt as getSessionEndSummaryQueryKey,
  Gt as getSessionGrantedAccessRulesQueryKey,
  vt as getSessionPresenceKey,
  nn as getSessionQuery,
  Mt as getSessionQueryKey,
  mn as getStreamTokenQueryKey,
  ae as getUpcomingSessionQueryKey,
  Ut as getUserMeetingTypesQueryKey,
  Tt as getUserQueryKey,
  Lt as getUserTransactionsQueryKey,
  fn as hostOptInMutation,
  qt as hubspotGetCompanyQueryKey,
  ks as hubstopGetCompanyQuery,
  F as inactiveOptions,
  $s as initConfig,
  Us as integrationsProvidersQuery,
  W as jsonPatch,
  Ps as jsonPatchArrayFallbackValidator,
  ue as logger,
  Ks as meetingMemoryCacheService,
  js as meetingMemoryDeleteThreadMutation,
  Vs as meetingMemoryThreadMessagesQuery,
  Ws as meetingMemoryThreadQuery,
  xs as meetingMemoryThreadsQuery,
  d as milliseconds,
  yn as pastSessionQuery,
  ln as paymentDetailsQuery,
  Vt as queryDataDefaultOptions,
  zs as recordingEpisodesQuery,
  Js as recordingQuery,
  en as requestAccessMutation,
  Cn as sessionCacheService,
  qs as sessionEndSummaryQuery,
  sn as sessionGrantedAccessRulesQuery,
  Zs as sessionPendingAccessQuery,
  Se as sessionPendingAccessQueryKey,
  un as sessionPresenceQuery,
  _n as streamTokenQuery,
  cn as upcomingSessionQuery,
  an as updateParticipantMetadataMutation,
  tn as updateSessionAccessMutation,
  rn as updateSessionMutation,
  An as useInfiniteQuery,
  On as useQuery,
  Rn as useQueryData,
  pn as userMeetingTypesQuery,
  Sn as userQuery,
  Xs as userSessionRequestAccessQuery,
  Dt as userSessionRequestAccessQueryKey,
  dn as userTransactionsQuery
};
