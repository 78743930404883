import {
  createListenerMiddleware,
  ListenerEffectAPI,
  ThunkDispatch,
  UnknownAction,
} from '@reduxjs/toolkit';
import { AUTHENTICATED, selectAuthState, SetupAuthAction } from '@waitroom/auth';
import { CommonState } from '@waitroom/common';
import { connectUserToBraze } from '../../core/services/braze';

export type ListenerApi = ListenerEffectAPI<
  CommonState,
  ThunkDispatch<CommonState, unknown, SetupAuthAction | UnknownAction>,
  unknown
>;
const authListenerMiddleware = createListenerMiddleware<
  CommonState,
  ThunkDispatch<CommonState, unknown, SetupAuthAction | UnknownAction>
>();

authListenerMiddleware.startListening({
  type: AUTHENTICATED,
  effect: async (_action, listenerApi) => {
    // cancel other running instances
    listenerApi.cancelActiveListeners();

    const state = selectAuthState(listenerApi.getState());
    connectUserToBraze(state);
  },
});

export { authListenerMiddleware };
