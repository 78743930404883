import { windowDefined } from '@waitroom/utils';
import Bowser from 'bowser';
import { DeviceType } from '../../../../constants/DeviceType';
import { SupportedBrowsers } from '../../../../constants/supportedBrowsers';

export const fcWidgetService = {
  isEnabled: (): boolean => windowDefined() && !!window.fcWidget,
  open: (): void => {
    if (window.fcWidget) window.fcWidget.open();
  },
};

export const userAgent =
  windowDefined() && window.navigator ? window.navigator.userAgent : undefined;
export const browser = userAgent ? Bowser.getParser(userAgent) : undefined;

export const deviceType =
  browser !== undefined ? (browser.getPlatformType(true) as DeviceType) : undefined;
export const isMobileDevice = deviceType === DeviceType.MOBILE || deviceType === DeviceType.TABLET;
export const browserName = browser?.getBrowserName(true);
export const browserNameBeautified = browserName?.replace(/(^\w{1})|(\s+\w{1})/g, (c) =>
  c.toUpperCase(),
);
export const osName = browser?.getOSName(true);
export const isSafari = browserName === 'safari';
export const isFirefox = browserName === 'firefox';
export const isChrome = browserName === 'chrome';
export const isEdge = browserName === 'edge';

export type BrowserSupportOptions = {
  browsers?: SupportedBrowsers;
  devices?: DeviceType[];
};
export const supportService = (() => {
  const supported: Record<string, boolean> = {};
  const isDevice = (d: DeviceType): boolean => !!browser && d === deviceType;
  const satisfies = (browsers: SupportedBrowsers): boolean =>
    (!!browser && browser.satisfies(browsers)) ?? false;
  const isSupported = (key: string, { browsers, devices }: BrowserSupportOptions): boolean => {
    supported[key] =
      supported[key] ??
      ((!browsers || satisfies(browsers)) && (!devices || devices.every(isDevice)));
    return supported[key];
  };

  return {
    device: deviceType,
    supported,
    isSupported,
    isDevice,
    satisfies,
  };
})();
