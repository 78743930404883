import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';
import { environments } from '../../core/config';
import { resources } from '../res/translation';

export const i18n = i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(intervalPlural)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    lng: 'en', // TEMP: force en language
    fallbackLng: 'en',
    debug: !environments.isProd && environments.env !== 'test',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });
