import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

export const DEFAULT_TIMEZONE = 'America/New_York';
export const CURRENT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

// 1. when extending dayjs add plugin typescript types to tsconfig.json
// 2. be aware of timezone offsets
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);
dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.tz.setDefault(dayjs.tz.guess() || CURRENT_TIMEZONE || DEFAULT_TIMEZONE);
