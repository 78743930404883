import { PaddleEventData } from '@paddle/paddle-js';
import { AnalyticsBrowser, Integrations, Traits } from '@segment/analytics-next';
import * as Sentry from '@sentry/react';
import { EventPayloads, MeetingLifecycleEventTypes } from '@waitroom/analytics';
import { httpService } from '@waitroom/common-api';
import { Session } from '@waitroom/models';
import posthog from 'posthog-js';
import { commonConfig, version } from '../../core/config';
import { branchService } from '../../core/services/branch';
import { envs } from '../../core/services/envs';
import { enabled as posthogEnabled } from '../../core/services/posthog';
import { enabled as sentryEnabled } from '../../core/services/sentry';
import { CheckoutEventConverter } from '../utils/events';
const writeKey = envs.VITE_SEGMENT_ID;

const enabled = !!writeKey;
const segmentClient = new AnalyticsBrowser();

export const analyticsService = {
  context: undefined as Dict | undefined,
  setParams: function setParams(params: { impactRadius?: string }) {
    this.context = params?.impactRadius ? { type: 'impactRadius', id: params?.impactRadius } : {};
  },
  init: async function init() {
    if (!enabled) return;
    segmentClient.load({ writeKey });
    branchService.init();
    posthog.opt_in_capturing();
  },
  user: async function user(
    props: Record<string, unknown> = {},
    integrations: Integrations = { All: true },
  ) {
    const { userId, email } = props;
    if (!userId) return;
    segmentClient.identify(
      userId,
      {
        ...props,
        version,
      },
      { context: this.context, integrations },
    );
    branchService.setIdentity(`${userId}`);
    if (posthogEnabled) posthog.identify(`${userId}`, { id: userId, email });
    if (sentryEnabled) Sentry.setUser({ id: `${userId}` });
  },
  track: async function track<T extends keyof EventPayloads>(
    event: T,
    props: EventPayloads[T],
    integrations: Integrations = { All: true },
  ) {
    if (!enabled) return;
    analyticsService.trackGA(event);
    return segmentClient.track(event, props, {
      context: this.context,
      integrations,
    });
  },
  trackSurvey: async function trackSurvey<T extends keyof EventPayloads>(
    event: T,
    props: EventPayloads[T],
  ) {
    if (!enabled) return;
    await httpService.post({
      baseURL: commonConfig.segment.trackUrl,
      data: {
        event,
        properties: props,
        writeKey,
        type: 'track',
        userId: 'userId' in props ? props.userId : undefined,
        integrations: {
          All: true,
        },
      },
      headers: { 'Content-Type': 'application/json' },
      throwError: false,
    });
  },
  trackCheckoutEvent: async (paddleEvent?: PaddleEventData) => {
    if (!paddleEvent || !paddleEvent.name || !paddleEvent.data) return;
    const event = CheckoutEventConverter.convertPaddleCheckoutEventName(paddleEvent.name);
    if (!event) return;
    const data = CheckoutEventConverter.convertPaddleEventToAnalyticsPayload(paddleEvent.data);
    analyticsService.track(event, data);
  },
  trackWithSession: async (event: MeetingLifecycleEventTypes, session?: Session) => {
    if (!enabled || !session) return;
    const {
      sessionID: meetingId,
      sessionSettings: {
        summaAI: aiEnabled,
        enableRecording: recordingEnabled,
        preferredLanguages,
      },
      startTimestamp,
    } = session;
    analyticsService.track(event, {
      meetingId: meetingId,
      isPrivate: session.accessStatus === 'locked',
      recordingEnabled: recordingEnabled ?? false,
      aiEnabled: aiEnabled ?? false,
      slotLengthSeconds: undefined,
      isInstant: !startTimestamp,
      languages: preferredLanguages,
    });
  },
  page: async function page(
    name: string,
    props: Record<string, unknown> = {},
    integrations: Integrations = { All: true },
  ) {
    if (!enabled) return;
    return segmentClient.page(name, props, { integrations, context: this.context });
  },
  logout: async () => {
    return segmentClient.reset();
  },
  trackGA: async (event: string) => {
    if (!window || !window.gtag) {
      return;
    }
    window.gtag('event', event, {
      event_category: 'engagement',
    });
  },
  setTraits: async (attributes: Traits) => {
    if (!enabled) return;

    return segmentClient.identify(attributes);
  },
};
