/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType, lazy, PropsWithoutRef, ReactElement, ReactNode, Suspense } from 'react';
import { unregister } from '../services/serviceWorker';

interface Opts<T> {
  importFn: T;
  fallback: ReactNode;
}

const maxAttempts = 3;
const componentLoader = async <T extends () => Promise<any>, U extends PropsWithoutRef<any>>(
  lazyComponent: T,
  attempt = 1,
  backOffMs = 200,
): Promise<{ default: ComponentType<U> }> => {
  try {
    return await lazyComponent();
  } catch (err) {
    if (attempt >= maxAttempts) {
      unregister();
      throw err;
    }

    // wait for backoff period
    await new Promise((resolve) => setTimeout(resolve, backOffMs * Math.pow(2, attempt)));

    return await componentLoader(lazyComponent, attempt + 1);
  }
};

export const lazyLoad = <T extends () => Promise<any>, U extends PropsWithoutRef<any>>({
  importFn,
  fallback,
}: Opts<T>) => {
  const LazyComponent = lazy(() => componentLoader<T, U>(importFn));
  return (props: U): ReactElement | null => (
    <Suspense fallback={fallback || null}>
      <LazyComponent {...(props as any)} />
    </Suspense>
  );
};
