/* eslint-disable @typescript-eslint/no-explicit-any */
import * as Sentry from '@sentry/react';
import { errorService } from '@waitroom/error-service';
import { toBoolean } from '@waitroom/utils';
import { environments, version } from '../../config';
import { envs } from '../envs';

const domain = envs.VITE_SENTRY_DSN;
const isEnabled = toBoolean(envs.VITE_SENTRY_ENABLED);
export const enabled = isEnabled && !!domain;

export const init = (): void => {
  if (enabled) {
    Sentry.init({
      dsn: domain,
      release: `web-app@${version}`,
      environment: environments.customEnv,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 1.0,
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    });
  }
  errorService.init({
    log: enabled
      ? (err: unknown): string | undefined => {
          if (!err) return undefined;
          if (err instanceof Error) return Sentry.captureException(err);
          if (typeof err === 'object') {
            const { error, message } = err as any;
            return Sentry.captureException(new Error(error || message || JSON.stringify(err)));
          }
          return Sentry.captureException(new Error(String(err)));
        }
      : undefined,
  });
};
