import { createAction as f } from "@waitroom/react-utils";
import { useSelector as U, useDispatch as g } from "react-redux";
import rt from "deepmerge";
import { produce as ot } from "immer";
import { authApiService as T, authBaseEndpoint as G, httpService as at, braidService as ut, userApiService as L } from "@waitroom/common-api";
import { STORAGE_AUTH_TOKENS as C, LOGGER_SERVICE_AUTH as _, LOGGER_SERVICE_BRAID as z } from "@waitroom/models";
import { tryCatchAsync as F, withLock as V, retry as ht, isFunction as dt } from "@waitroom/utils";
import { jwtDecode as ft } from "jwt-decode";
import { useMemo as Tt } from "react";
import { useMutation as P } from "@tanstack/react-query";
import { isErrorCode as St } from "@waitroom/braid";
import { bearerValue as y } from "@waitroom/http-client";
import { createListenerMiddleware as mt } from "@reduxjs/toolkit";
import { getRequestData as O } from "@waitroom/react-query";
const x = "refresh-token", p = "auth/INIT_AUTH", B = "auth/SETUP_AUTH", j = "auth/LOGOUT", q = "auth/SET_CURRENT_USER", W = "auth/UPDATE_CURRENT_USER", Wt = "auth/SET_CURRENT_USER_SUBSCRIPTION_PLAN", K = "auth/SET_AUTH_STATE", J = "auth/UPDATE_AUTH_STATE", Q = "auth/SET_SUBSCRIPTION_PLAN", X = "auth/REFRESH_SUBSCRIPTION_PLAN", Y = "auth/AUTHENTICATED", lt = "auth/UN_AUTHENTICATED", Z = "auth/GET_CURRENT_USER", Kt = f(p), $ = f(B), v = f(
  K
), m = f(J), _t = f(Z), Jt = f(
  q
), Qt = f(W), kt = f(j), b = f(Y), I = f(lt), tt = f(Q), Xt = f(X);
let o;
const Yt = (e) => {
  o = e.logger;
}, Et = (e) => e.reduce(
  (t, s) => ({
    ...t,
    [s]: !0
  }),
  {}
), w = {
  userId: void 0,
  isAuthenticated: !1,
  status: "initialized",
  isGuest: !1
}, S = {
  _refreshPromise: void 0,
  _cache: {},
  config: {},
  init: function(e) {
    this.config = e;
  },
  getAnonymous: () => w,
  getDecodedToken: function(e) {
    if (this._cache[e]) return this._cache[e];
    const t = ft(e);
    if (t)
      return this._cache[e] = t, t;
  },
  setup: async function(e, t = !0) {
    const s = e || this.getStorageTokens();
    if (!(s != null && s.accessToken)) return w;
    const n = this.decode(s, 600);
    n != null && n.accessToken && this.saveStorageTokens({
      accessToken: n.accessToken,
      refreshToken: n.refreshToken
    });
    const i = n || (t ? await this.refresh(s.refreshToken) : void 0);
    return i != null && i.accessToken ? i : this.logout();
  },
  getStorageTokens: function() {
    var e;
    return (e = this.config.storage) == null ? void 0 : e.getParsed(C);
  },
  saveStorageTokens: function(e) {
    var t;
    (t = this.config.storage) == null || t.set(C, e);
  },
  clearStorageTokens: function() {
    var e;
    (e = this.config.storage) == null || e.remove(C);
  },
  isTokenValid: function(e, t = 0) {
    const s = e ? this.getDecodedToken(e) : void 0;
    return !!s && !this.hasExpired(s.exp, t);
  },
  _refreshFn: async function(e) {
    var c;
    if (!e) return;
    const t = await F(
      () => T.refreshToken({
        data: {
          refreshToken: e
        }
      })
    )();
    if ((t == null ? void 0 : t.code) === 401) return;
    const { authToken: s, refreshToken: n } = ((c = t == null ? void 0 : t.data) == null ? void 0 : c.data) || {};
    if (!s) return;
    const i = { accessToken: s, refreshToken: n }, r = this.decode(i);
    if (r)
      return this.saveStorageTokens(i), r;
  },
  refresh: async function(e) {
    var i;
    if (o == null || o.service(_, 1, "Refreshing auth token"), this._refreshPromise) return await this._refreshPromise;
    const t = (i = this.getStorageTokens()) == null ? void 0 : i.refreshToken, s = e || t;
    if (!s) return;
    this._refreshPromise = this._refreshFn(s);
    const n = await this._refreshPromise || await this._refreshFn(t);
    return o == null || o.service(_, 1, "Refreshed auth token", n == null ? void 0 : n.refreshToken), this._refreshPromise = void 0, n;
  },
  /** Decode token with expiration tolerance in seconds */
  decode: function(e, t) {
    const {
      userID: s,
      guestSurrogateID: n,
      guestEmail: i,
      guestFullName: r,
      exp: c,
      roleIDs: u = []
    } = this.getDecodedToken(e.accessToken) || {}, a = s || (n ? String(n) : void 0);
    if (!a || this.hasExpired(c, t)) return;
    const d = !s, l = {
      ...e,
      userId: a,
      isAuthenticated: !0,
      expires: c,
      agentId: a,
      isGuest: d,
      roles: Et(u),
      status: "initialized"
    };
    return d && (l.currentUser = {
      id: String(n),
      firstName: r || "",
      email: i || ""
    }), l;
  },
  logout: async function() {
    return await T.logout({ throwError: !1 }), this.clearStorageTokens(), w;
  },
  /** Check if token has expired with expiration tolerance in seconds */
  hasExpired: (e, t = 0) => !e || Date.now() + t * 1e3 > e * 1e3
}, et = S.getAnonymous(), st = {
  ...et,
  status: void 0
}, Zt = (e = st, t) => ot(e, (s) => {
  var n;
  switch (t.type) {
    case K:
      return t.payload || et;
    case J:
      return s = { ...s, ...t.payload }, s;
    case q:
      return s.currentUser = t.payload, s.userId = (n = t.payload) == null ? void 0 : n.id, s;
    case W:
      return t.payload && (s.currentUser = rt(
        s.currentUser || {},
        t.payload
      ), t.payload.id && (s.userId = t.payload.id)), s;
    case Q:
      return !s.currentUser || !t.payload || (s.currentUser.subscriptionPlan = t.payload), s;
    default:
      return e;
  }
}), h = (e) => e && e.auth ? e.auth : st, Ut = (e) => h(e).isAuthenticated, At = (e) => h(e).isGuest, yt = (e) => h(e).status, $t = (e) => h(e).userId, k = (e) => h(e).currentUser, te = (e) => {
  var t;
  return (t = k(e)) == null ? void 0 : t.id;
}, ee = (e) => {
  var t;
  return (t = k(e)) == null ? void 0 : t.customerID;
}, se = (e) => {
  var t;
  return (t = k(e)) == null ? void 0 : t.email;
}, Pt = (e) => h(e).roles, nt = (e) => {
  var t;
  return (t = k(e)) == null ? void 0 : t.notificationSettings;
}, ne = (e) => {
  var t;
  return (t = k(e)) == null ? void 0 : t.subscriptionPlan;
}, ie = (e) => {
  var t;
  return (t = k(e)) == null ? void 0 : t.team;
}, ce = (e) => {
  var t;
  return (t = nt(e)) == null ? void 0 : t.isPushNotificationEnabledGlobal;
}, re = (e) => {
  var t;
  return (t = nt(e)) == null ? void 0 : t.isEmailNotificationEnabledGlobal;
}, oe = (e) => {
  const { accessToken: t, refreshToken: s, expires: n } = h(e);
  return { accessToken: t, refreshToken: s, expires: n };
}, ae = (e) => h(e).accessToken, ue = (e) => h(e).agentId, he = () => {
  const e = U(h);
  return {
    state: e,
    isLoading: !e.status || e.status === "loading"
  };
}, de = (e) => {
  const t = U(Ut), s = U(At), n = U(yt), i = U(Pt), [r, c] = Tt(() => e ? t ? (typeof e == "string" ? [e] : e).reduce(
    (a, d) => {
      const l = !!i && !!i[d];
      return a[0][d] = l, a[1] = a[1] && l, a;
    },
    [{}, !0]
  ) : [{}, !1] : [{}, !0], [e, t, i]);
  return {
    isAuthenticated: t,
    isGuest: s,
    status: n,
    isLoading: !n || n === "loading",
    hasAccess: c,
    access: r,
    roles: i
  };
}, vt = (e) => ({
  ...e,
  mutationFn: async (t) => {
    const s = {
      ...t,
      email: t.email.toLowerCase()
    };
    return t.clientId && t.redirectUrl ? T.requestOAuthOTP({
      data: {
        ...s,
        redirect_uri: t.redirectUrl,
        client_id: t.clientId,
        state: t.state,
        codeChallenge: t.codeChallenge
      }
    }) : T.requestOTP({ data: s });
  }
}), It = (e) => ({
  ...e,
  mutationFn: async ({ email: t, otp: s, clientId: n, state: i, codeChallenge: r }) => n ? T.verifyOAuthOTP({
    data: {
      email: t.toLowerCase(),
      otp: s,
      client_id: n,
      state: i,
      codeChallenge: r
    }
  }) : T.verifyOTP({
    data: {
      email: t.toLowerCase(),
      otp: s
    }
  })
}), Rt = (e) => ({
  ...e,
  mutationFn: async ({
    token: t,
    code: s,
    type: n,
    userDetails: i,
    clientId: r,
    redirectUrl: c,
    state: u,
    codeChallenge: a
  }) => {
    if (!n || !s && !t) throw new Error("Invalid request");
    return r && c ? t ? T.verifyOAuthTokenProvider({
      data: {
        token: t,
        type: n,
        userDetails: i,
        client_id: r,
        redirect_uri: c,
        state: u,
        codeChallenge: a
      }
    }) : T.verifyOAuthCodeProvider({
      data: {
        code: s || "",
        type: n,
        client_id: r,
        redirect_uri: c,
        state: u,
        codeChallenge: a
      }
    }) : t ? T.verifyAuthTokenProvider({
      data: { token: t, type: n, userDetails: i }
    }) : T.verifyAuthCodeProvider({
      data: { code: s || "", type: n }
    });
  }
}), Ct = (e) => ({
  ...e,
  mutationFn: async (t) => T.logout(t)
}), fe = (e) => {
  const t = g();
  return P(
    Ct({
      ...e,
      onMutate: (...s) => {
        t(m({ status: "loading" })), e != null && e.onMutate && e.onMutate(...s);
      },
      onSuccess: (...s) => {
        t(m(S.getAnonymous())), t(I()), e != null && e.onSuccess && e.onSuccess(...s);
      }
    })
  );
}, Te = (e) => P(vt(e)), Se = ({ onRedirect: e, ...t }) => {
  const s = g();
  return P(
    Rt({
      ...t,
      onMutate: (...n) => {
        s(m({ status: "loading" })), t != null && t.onMutate && t.onMutate(...n);
      },
      onError: (n, i, r) => {
        s(m({ status: "initialized" })), t != null && t.onError && t.onError(n, i, r);
      },
      onSuccess: (n, i, r) => {
        var u;
        if (s(m({ status: "initialized" })), !(n != null && n.success)) return;
        const c = (u = n.data) == null ? void 0 : u.data;
        if (c) {
          if (i.clientId && i.redirectUrl) {
            "redirect_uri" in c && c.redirect_uri && e(c.redirect_uri);
            return;
          }
          if ("authToken" in c && c.authToken) {
            const { authToken: a, refreshToken: d } = c;
            s(
              $({
                accessToken: a,
                refreshToken: d
              })
            );
          }
          t != null && t.onSuccess && t.onSuccess(n, i, r);
        }
      }
    })
  );
}, me = ({ onRedirect: e, ...t }) => {
  const s = g();
  return P(
    It({
      ...t,
      onMutate: (...n) => {
        s(m({ status: "loading" })), t != null && t.onMutate && t.onMutate(...n);
      },
      onSuccess: (n, i, r) => {
        var u;
        if (s(m({ status: "initialized" })), !(n != null && n.success)) return;
        const c = (u = n.data) == null ? void 0 : u.data;
        if (c) {
          if (i.clientId) {
            "redirect_uri" in c && c.redirect_uri && e(c.redirect_uri);
            return;
          }
          if ("authToken" in c && c.authToken) {
            const { authToken: a, refreshToken: d } = c || {};
            if (!a) return;
            const l = i.redirectUrl;
            s(
              $({
                ...i,
                accessToken: a,
                refreshToken: d,
                onCompleted: l ? () => e(l) : void 0
              })
            );
          }
          t != null && t.onSuccess && t.onSuccess(n, i, r);
        }
      }
    })
  );
}, it = () => {
  const e = S.getStorageTokens();
  return e != null && e.accessToken ? [S.isTokenValid(e.accessToken, 30), e] : [!1, void 0];
}, wt = V(x, async (e) => {
  o == null || o.service(_, 3, "Refresh token interceptor lock");
  const [t, s] = it();
  if (t || !(s != null && s.refreshToken))
    return s == null ? void 0 : s.accessToken;
  const n = await S.refresh(s.refreshToken);
  if (n != null && n.accessToken)
    return e == null || e(n), n.accessToken;
}), R = async (e, t) => {
  const [s, n] = it();
  return s && e !== y(n.accessToken) ? n.accessToken : wt(t);
}, ct = St([401]), le = ({ onAuthUpdated: e }) => async (t, s) => {
  var n;
  if (o == null || o.service(z, 3, "Error interceptor", t.key, s), !t._retry && ct(s)) {
    const i = await R((n = t.headers) == null ? void 0 : n.Authorization, e);
    if (!i) throw s;
    t._retry = !0, t.headers = {
      ...t.headers,
      Authorization: y(i)
    };
    return;
  }
  throw s;
}, _e = ({ onAuthUpdated: e }) => async (t, s) => {
  var n;
  if (o == null || o.service(z, 3, "Retry interceptor", t.key, s), !t._retry && ct(s)) {
    const i = await R((n = t.headers) == null ? void 0 : n.Authorization, e);
    if (!i) throw s;
    t._retry = !0, t.headers = {
      ...t.headers,
      Authorization: y(i)
    };
    return;
  }
}, ke = ({ onRetry: e, onAuthUpdated: t }) => async (s) => {
  var i, r;
  e == null || e(s);
  const n = s == null ? void 0 : s.config;
  if (o == null || o.logWith(3, "Axios auth interceptor", s), ((i = s == null ? void 0 : s.response) == null ? void 0 : i.status) === 401 && !(n != null && n._retry) && !String(n.url).startsWith(G)) {
    n._retry = !0;
    const c = (r = n.headers.Authorization) == null ? void 0 : r.toString(), u = await R(c, t);
    return o == null || o.service(_, 3, "Axios auth interceptor", u), u ? (n.headers.Authorization = y(u), at.client(n)) : Promise.reject(s);
  }
  return Promise.reject(s);
}, Ee = ({ onRetry: e, onAuthUpdated: t }) => async (s, n) => {
  var c, u;
  e == null || e(n);
  const i = s;
  if ((n && typeof n == "object" ? (n == null ? void 0 : n.code) || (n == null ? void 0 : n.status) : n) === 401 && !(i != null && i.retried) && !String(i.url).startsWith(G)) {
    i.retried = !0;
    const a = (u = (c = i.headers) == null ? void 0 : c.Authorization) == null ? void 0 : u.toString(), d = await R(a, t);
    return d ? (i.headers = {
      ...i.headers,
      Authorization: y(d)
    }, ut.fetch(i)) : Promise.reject(n);
  }
  return Promise.reject(n);
}, D = (e) => !!e.isAuthenticated && !!e.userId && !e.isGuest, E = mt();
let A;
const Nt = (e, t) => new Promise(async (s) => {
  A && clearTimeout(A);
  const { expires: n, refreshToken: i } = e;
  if (!n || !i) return;
  const r = ~~(6e4 + Math.random() * 24e4), c = Math.max(0, n * 1e3 - Date.now() - r);
  o == null || o.service(_, 3, "Refresh auth token setup timeout", {
    time: c
  }), A = setTimeout(
    V(x, async () => {
      o == null || o.service(_, 3, "Refresh auth token timeout");
      const a = await S.refresh(e.refreshToken) || await S.logout();
      return a && (t.dispatch(m(a)), t.dispatch(a.isAuthenticated ? b() : I())), s(a);
    }),
    c
  );
}), M = async (e, t) => {
  const s = await Nt(e, t);
  return s != null && s.isAuthenticated && M(s, t), s;
}, gt = 6e5;
let N;
const Lt = (e) => new Promise(async (t) => {
  N = setTimeout(async () => {
    const s = await L.getSubscriptionPlan(e);
    return t(s);
  }, gt);
});
async function H(e) {
  N && clearTimeout(N);
  const t = h(e.getState());
  if (!D(t)) return;
  const s = await Lt(t.userId), n = O(s);
  n && s.success && e.dispatch(tt(n)), H(e);
}
E.startListening({
  type: p,
  effect: async (e, t) => {
    t.cancelActiveListeners();
    const s = await S.setup();
    s.refreshToken && t.fork(async () => {
      M(s, t);
    }), t.dispatch(v(s)), t.dispatch(s.isAuthenticated ? b() : I());
  }
});
E.startListening({
  type: Z,
  effect: async (e, t) => {
    var r;
    t.cancelActiveListeners();
    const s = h(t.getState());
    if (!D(s)) return;
    const n = await F(
      () => ht(L.getCurrent, {
        retries: 2
      })
    )();
    if (n != null && n.code && n.code >= 400 && n.code <= 500) {
      t.dispatch(kt());
      return;
    }
    const i = (r = O(n)) == null ? void 0 : r.user;
    if (i) {
      const c = {
        id: i.userID,
        firstName: i.userFirstName,
        lastName: i.userLastName,
        ...i
      }, a = { ...h(t.getState()), currentUser: c };
      t.dispatch(v(a)), t.fork(() => H(t));
      return;
    }
  }
});
E.startListening({
  type: B,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  effect: async (e, t) => {
    t.cancelActiveListeners(), o == null || o.service(_, 1, "SETUP_AUTH");
    const { accessToken: s, refreshToken: n, onCompleted: i } = e.payload || {}, r = await S.setup({
      accessToken: s,
      refreshToken: n
    });
    r.refreshToken && t.fork(() => M(r, t)), r.isAuthenticated && t.fork(() => H(t)), t.dispatch(v(r)), t.dispatch(b()), t.dispatch(m({ status: "initialized" })), i && (dt(i) ? i() : t.dispatch(i));
  }
});
E.startListening({
  type: Y,
  effect: async (e, t) => {
    t.cancelActiveListeners(), t.dispatch(_t());
  }
});
E.startListening({
  type: j,
  effect: async (e, t) => {
    t.cancelActiveListeners(), t.dispatch(m({ status: "loading" })), A && clearTimeout(A), h(t.getState()).isAuthenticated && await S.logout(), t.dispatch(v(S.getAnonymous())), t.dispatch(I());
  }
});
E.startListening({
  type: X,
  effect: async (e, t) => {
    t.cancelActiveListeners();
    const s = h(t.getState());
    if (!D(s)) return;
    const n = await L.getSubscriptionPlan(s.userId), i = O(n);
    i && n.success && t.dispatch(tt(i));
  }
});
export {
  Y as AUTHENTICATED,
  Z as GET_CURRENT_USER,
  p as INIT_AUTH,
  j as LOGOUT,
  X as REFRESH_SUBSCRIPTION_PLAN,
  B as SETUP_AUTH,
  K as SET_AUTH_STATE,
  q as SET_CURRENT_USER,
  Wt as SET_CURRENT_USER_SUBSCRIPTION_PLAN,
  Q as SET_SUBSCRIPTION_PLAN,
  lt as UN_AUTHENTICATED,
  J as UPDATE_AUTH_STATE,
  W as UPDATE_CURRENT_USER,
  st as authInitialState,
  E as authListenerMiddleware,
  Zt as authReducer,
  et as authResetState,
  S as authService,
  b as authenticated,
  ke as axiosAuthInterceptor,
  Ee as braidOnError,
  le as braidOnErrorInterceptor,
  _e as braidOnRetryInterceptor,
  R as getAccessToken,
  _t as getCurrentUser,
  Kt as initAuth,
  Yt as initConfig,
  o as logger,
  kt as logout,
  Ct as logoutMutation,
  Xt as refreshSubscriptionPlan,
  x as refreshTokenLockName,
  vt as requestOTPMutation,
  ue as selectAgentId,
  ae as selectAuthAccessToken,
  h as selectAuthState,
  yt as selectAuthStatus,
  oe as selectAuthToken,
  $t as selectAuthUserId,
  k as selectCurrentUser,
  ee as selectCurrentUserCustomerId,
  se as selectCurrentUserEmail,
  re as selectCurrentUserEmailSetting,
  te as selectCurrentUserId,
  ce as selectCurrentUserPushSetting,
  Pt as selectCurrentUserRoles,
  nt as selectCurrentUserSettings,
  ne as selectCurrentUserSubscriptionPlan,
  ie as selectCurrentUserTeam,
  Ut as selectIsAuthenticated,
  At as selectIsCurrentUserGuest,
  v as setAuthState,
  Jt as setCurrentUser,
  tt as setSubscriptionPlan,
  $ as setupAuth,
  I as unauthenticated,
  m as updateAuthState,
  Qt as updateCurrentUser,
  he as useAuth,
  de as useHasAccess,
  fe as useLogout,
  Te as useRequestOTP,
  Se as useVerifyAuthProvider,
  me as useVerifyOTP,
  Rt as verifyAuthProviderMutation,
  It as verifyOTPMutation
};
